@import "../../_globalColor";

.card-image {
  max-width: 100%;
  height: auto;
  width: 250px;
}
.card-title {
  color: $titleColor;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}
.card-subtitle {
  color: $cardSubtitle;
  font-size: 17px;
  line-height: 1.5rem;
}
.project-card {
  display: flex;
  flex-direction: column;
  background-color: $lightBackground2;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  justify-content: center;
}
.project-card-light {
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  box-shadow: 0px 10px 30px -15px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.project-detail {
  text-align: center;
}
.project-card-light:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}
.project-card-dark:hover {
  box-shadow: 0px 20px 30px -10px $darkBoxShadow;
}
.project-image {
  position: relative;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
}

.project-card-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-card-footer span.project-tag {
  background: $buttonColor;
  color: $lightBackground3;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0.5rem; /* Espacement entre les boutons */
  padding: 0 0.75em;
  cursor: pointer;
  transition: all 0.3s ease; /* Transition pour l'effet de survol */
}

.project-card-footer span.project-tag:hover {
  transform: translateY(-3px); /* Effet de léger décalage vers le haut */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre douce au survol */
}
.technologies {
  display: flex;
  flex-wrap: wrap; // Permet aux boutons de passer à la ligne lorsque l'espace est insuffisant
  justify-content: center;
  margin-bottom: 20px; // Espace en bas de la liste de boutons de technologie
}

.project-button {
  margin-right: 5px; // Espace entre chaque bouton de technologie
  margin-bottom: 5px; // Espace en bas de chaque bouton de technologie
  margin-top: 0px;
  padding: 8px 16px; // Remplissage interne pour chaque bouton
  border-radius: 4px; // Bordures arrondies pour un aspect plus moderne
  background-color: $buttonColor; // Couleur de fond des boutons
  color: rgb(241, 241, 241); // Couleur du texte des boutons
  font-size: 12px; // Taille de la police
  font-weight: 500; // Poids de la police
  cursor: default; // Désactive le curseur pointer
  transition: background-color 0.3s ease, border-color 0.3s ease; // Transition fluide pour l'effet de survol
}

.project-button:hover {
  background-color: $buttonHover; // Couleur de fond des boutons au survol
  border-color: $buttonHover; // Couleur de la bordure au survol
}


@media (max-width: 768px) {
  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}
.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colonnes */
  gap: 20px;
  justify-content: center;
  align-items: start;
  width: 100%;
  max-width: 1200px; /* Empêche l'étirement excessif */
  margin: 0 auto;
}

/* RESPONSIVE : 2 colonnes sur tablette */
@media (max-width: 992px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* RESPONSIVE : 1 colonne sur mobile */
@media (max-width: 600px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Style des cartes projets */
.project-card {
  display: flex;
  flex-direction: column;
  background-color: $lightBackground2;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  justify-content: center;
  align-items: center;
  text-align: center;
}
